import React, { useState, useEffect } from "react";
import APIRoute from "../routersAPI/routes.json";
import {Link, useParams } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {
  FormGroup,
  InputLabel,
  TextField,
  Button,
  Grid,
  Card,
  Alert,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import { IoIosArrowBack } from "react-icons/io";


export default function EditClient() {
  const { id } = useParams();
  const [rutClient, setRutClient] = useState("");
  const [nameClient, setNameClient] = useState("");
  const [emailClient, setEmailClient] = useState("");
  const [phoneClient, setPhoneClient] = useState("");
  const [validationError, setValidationError] = useState({});

  useEffect(() => {
    fetchClient();
  }, []);

  const fetchClient = async () => {
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .get(APIRoute.client + id, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        if (typeof data == "object") {
          setRutClient(data["rut_client"]);
          setNameClient(data["name_client"]);
          setEmailClient(data["email_client"]);
          setPhoneClient(data["phone_client"]);
        } else {
          setValidationError("El cliente no se encuentra o fue eliminado");
        }
      })
      .catch(() => {
        Swal.fire({
          text: "error cargando cliente",
          icon: "error",
        });
      });
  };
  const updateClient = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("id", id);
    formData.append("rut", rutClient);
    formData.append("name", nameClient);
    formData.append("phone", phoneClient);
    formData.append("email", emailClient);
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .post(APIRoute.editClient + id, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
        params: {
          _method: "patch",
        },
      })
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
      })
      .catch(() => {
        setValidationError("Revisar campos");
      });
  };
  return (
    <Container fixed>
      <br />
      <Grid>
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Card sx={{ minWidth: 500, my: 3 }}>
            <Typography className="text-center align-items-center" variant="h6">Actualizar Datos Cliente</Typography>
            <hr />
            <FormGroup>
              <Grid
                direction="column"
                alignItems="center"
                justifyContent="center"
                container
              >
                {Object.keys(validationError).length > 0 && (
                  <Grid direction="row">
                    <Alert variant="filled" severity="error">
                      {validationError}
                    </Alert>
                  </Grid>
                )}
                <br />
                <Typography>Rut cliente</Typography>
                <TextField
                  sx={{ minWidth: 350 }}
                  placeholder="Ingrese rut del cliente"
                  value={rutClient}
                  required
                  onChange={(event) => {
                    setRutClient(event.target.value);
                  }}
                />
                <br />
                <Typography>Nombre cliente</Typography>
                <TextField
                  sx={{ minWidth: 350 }}
                  placeholder="Ingrese nombre del cliente"
                  value={nameClient}
                  required
                  onChange={(event) => {
                    setNameClient(event.target.value);
                  }}
                />
                <br />
                <Typography>Correo cliente</Typography>
                <TextField
                  sx={{ minWidth: 350 }}
                  placeholder="Ingrese email del cliente"
                  value={emailClient}
                  required
                  onChange={(event) => {
                    setEmailClient(event.target.value);
                  }}
                />
                <br />
                <Typography>Teléfono cliente</Typography>
                <TextField
                  sx={{ minWidth: 350 }}
                  placeholder="Ingrese teléfono del cliente"
                  value={phoneClient}
                  required
                  onChange={(event) => {
                    setPhoneClient(event.target.value);
                  }}
                />
                <br />
                <Grid>
                <Link to={"/"} color="success">
                    <Button
                      type="submit"
                      sx={{ m: 3 }}
                      variant="contained"
                      title="Volver"
                    >
                      <Typography variant="h6">
                        <IoIosArrowBack style={{ fontSize: "25px" }} />
                        <Typography sx={{ mx: 1 }} variant>
                          Volver
                        </Typography>
                      </Typography>
                    </Button>
                  </Link>
                <Button
                title="Ingresar"
                  variant="contained"
                  color="success"
                  onClick={updateClient}
                >
                  <Typography variant="h6">Ingresar</Typography>
                </Button>
              </Grid>
              </Grid>
            </FormGroup>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
