import React, { useState, useEffect } from "react";
import APIRoute from "../routersAPI/routes.json";
import { useNavigate, Link } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import {
  FormGroup,
  TextField,
  Button,
  Grid,
  Select,
  MenuItem,
  Box,
  Typography,
  Paper,
  Container,
} from "@mui/material";
import GoogleMaps from "./map";
import { IoIosArrowBack } from "react-icons/io";

export default function CreateClient() {
  const navigate = useNavigate();
  const [rutClient, setRutClient] = useState("");
  const [nameClient, setNameClient] = useState("");
  const [emailClient, setEmailClient] = useState("");
  const [phoneClient, setPhoneClient] = useState("");
  const [errorMessage, setErrorMessage] = useState({});
  const [idAdministrator] = useState(sessionStorage.getItem("id"));
  const [addressApi, setAddressApi] = useState("");
  const [typeOfAsset, setTypeOfAsset] = useState("");
  const [typeOfAssetList, setTypeOfAssetList] = useState([]);
  const [region, setRegion] = useState("");
  const [commune, setCommune] = useState("");
  const [communeList, setCommuneList] = useState([]);
  const [regionList, setRegionList] = useState([]);
  const [supervisor, setSupervisor] = useState("");
  const [supervisorList, setSupervisorList] = useState([]);
  const [rol, setRol] = useState("");
  const [terrainArea, setTerrainArea] = useState("");
  const [constructionArea, setConstructionArea] = useState("");
  const [bedroom, setBedroom] = useState("");
  const [bathroom, setBathroom] = useState("");

  useEffect(() => {
    fetchRegionList();
    fetchTypeOfAssetList();
    selectSupervisor();
  }, []);

  const fetchRegionList = async () => {
    let tokenStr = sessionStorage.getItem("token");
    axios
      .get(APIRoute.region, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        setRegionList(data);
      });
  };
  const fetchTypeOfAssetList = async () => {
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .get(APIRoute.asset, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        setTypeOfAssetList(data);
      });
  };
  const selectSupervisor = async () => {
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .get(APIRoute.supervisor, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        setSupervisorList(data);
      });
  };

  const handleCommune = async (commune) => {
    const formData = new FormData();
    formData.append("id", commune);
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .post(APIRoute.commune, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        setCommuneList(data);
      })
      .catch(({ response }) => {
        if (response.status === 500) {
          setErrorMessage(response.data.errors);
        }
      });
  };

  const createClient = async () => {
    const formData = new FormData();
    formData.append("rut", rutClient);
    formData.append("name", nameClient);
    formData.append("phone", phoneClient);
    formData.append("email", emailClient);
    formData.append("type", "client");
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .post(APIRoute.createClient, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        const idClient = data["id_client"];
        createAccessKey(idClient);
      })
      .catch(({ response }) => {
        if (response.status) {
          setErrorMessage(response.data.errors);
          console.log(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      })
      .finally(() => {});
  };
  const createAccessKey = async (idClient) => {
    const formData = new FormData();
    formData.append("idAdministrator", idAdministrator);
    let tokenStr = sessionStorage.getItem("token");
    axios
      .post(APIRoute.code, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        const geocoder = new window.google.maps.Geocoder();
        getvalues(data.access_code["id_access_code"], idClient, geocoder);
      })
      .catch(({ response }) => {
        if (response) {
          setErrorMessage(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      })
      .finally(() => {});
  };
  const geocodePlaceId = async (geocoder) => {
    const placeId = sessionStorage.getItem("placeid");
    await geocoder
      .geocode({ placeId: placeId })
      .then(({ results }) => {
        sessionStorage.setItem("latitude", results[0].geometry.location.lat());
        sessionStorage.setItem("longitude", results[0].geometry.location.lng());
      })
      .catch((e) => window.alert("Geocoder failed due to: " + e));
  };
  const getvalues = async (accessCode, idClient, geocoder) => {
    setAddressApi(sessionStorage.getItem("addressApi"));
    await geocodePlaceId(geocoder);
    if (bathroom >= 1) {
      setBathroom(0);
    }
    if (bedroom >= 1) {
      setBedroom(0);
    }
    const nameAddress = addressApi.split(",");
    const tempNumber = nameAddress[0];
    const addressNumber = tempNumber.split(" ");
    createAppreciation(accessCode, idClient, addressNumber, nameAddress);
  };
  const createAppreciation = async (
    accessCode,
    idClient,
    addressNumber,
    nameAddress
  ) => {
    const formData = new FormData();
    formData.append("id_client", idClient);
    formData.append("id_administrator", idAdministrator);
    formData.append("id_type_of_assets", typeOfAsset);
    formData.append("id_access_code", accessCode);
    formData.append("id_commune", commune);
    formData.append("address", nameAddress);
    formData.append("address_number", addressNumber[addressNumber.length - 1]);
    formData.append("rol", rol);
    formData.append("terrain_area", terrainArea);
    formData.append("construction_area", constructionArea);
    formData.append("bedrooms", bedroom);
    formData.append("bathrooms", bathroom);
    formData.append("latitude", sessionStorage.getItem("latitude"));
    formData.append("longitude", sessionStorage.getItem("longitude"));
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .post(APIRoute.appreciation, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        Swal.fire({
          icon: "success",
          text: data.message,
        });
        sendEmail(data.id_appreciation["id_appreciation"], data.valo, data.wit);
      })
      .catch(({ response }) => {
        if (response) {
          setErrorMessage(response.data.errors);
        } else {
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      })
      .finally(() => {});
  };
  const sendEmail = async (id_appreciation, valo, wit) => {
    // const objectData = new FormData();
    // const objectData2 = new FormData();
    // valo.map((valo, index) => {
    //   objectData.append(index, valo[index]);
    // });
    // wit.map((wit, index) => {
    //   objectData2.append(index, wit[index]);
    // })
    const formData = new FormData();
    formData.append("id_appreciation", id_appreciation);
    formData.append("email_supervisor", supervisor);
    formData.append("valo", JSON.stringify(valo));
    console.log(valo);
    formData.append("wit", JSON.stringify(wit));
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .post(APIRoute.sendExcel, formData, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(({ data }) => {
        console.log(data);
        navigate("/client");
      })
      .catch(({ response }) => {
        if (response) {
          setErrorMessage(response.data.errors);
          console.log("error if");
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        } else {
          console.log("error else");
          Swal.fire({
            text: response.data.message,
            icon: "error",
          });
        }
      });
  };

  return (
    <Box component="main" sx={{ p: 1, my: 2 }}>
      <Grid>
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Paper sx={{ width: "100%", overflow: "hidden" }}>
            <Box sx={{ display: { xs: "flex", md: "none" } }}>
              <Container>
                <Grid
                  direction="column"
                  alignItems="center"
                  justifyContent="center"
                  container
                >
                  <Typography
                    className="text-center align-items-center"
                    variant="h6"
                  >
                    Ingresar Datos Cliente
                  </Typography>
                  <hr />
                  <FormGroup>
                    <Typography className="text-center align-items-center">Rut cliente</Typography>
                    <TextField
                      sx={{ minWidth: 300, my: 2 }}
                      placeholder="Ej: 7654564-0"
                      value={rutClient}
                      required
                      onChange={(event) => {
                        setRutClient(event.target.value);
                      }}
                    />
                    <Typography className="text-center align-items-center">Nombre cliente</Typography>
                    <TextField
                      sx={{ minWidth: 300, my: 2}}
                      placeholder="Ej: Juan Pérez"
                      value={nameClient}
                      required
                      onChange={(event) => {
                        setNameClient(event.target.value);
                      }}
                    />
                    <Typography className="text-center align-items-center">Correo cliente</Typography>
                    <TextField
                      sx={{ minWidth: 300, my: 2 }}
                      placeholder="Ej: juanperez@gmail.com"
                      value={emailClient}
                      required
                      onChange={(event) => {
                        setEmailClient(event.target.value);
                      }}
                    />
                    <Typography className="text-center align-items-center">Teléfono cliente</Typography>
                    <Grid sx={{ my: 2}}>
                      <TextField
                        sx={{ width: 60, mx: 2 }}
                        disabled
                        value="+ 56"
                      ></TextField>
                      <TextField
                        sx={{ minWidth: 150 }}
                        placeholder="Ej: 98787678"
                        value={phoneClient}
                        required
                        onChange={(event) => {
                          setPhoneClient(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      container
                    >
                      <Typography>Seleccione Región</Typography>
                      <Select
                        value={region}
                        onChange={(event) => {
                          setRegion(event.target.value);
                          handleCommune(event.target.value);
                        }}
                        required
                        sx={{ minWidth: 300, my: 2 }}
                      >
                        <MenuItem value="" defaultValue disabled="disabled">
                          <em>Seleccione región</em>
                        </MenuItem>
                        {regionList.map((item, index) => (
                          <MenuItem key={index} value={item.id_region}>
                            {item.name_region}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography id="demo-simple-select-label">
                        Seleccione Comuna
                      </Typography>
                      <Select
                        value={commune}
                        onChange={(event) => {
                          setCommune(event.target.value);
                        }}
                        required
                        label="Comuna"
                        sx={{ minWidth: 300, my: 2 }}
                      >
                        <MenuItem value="" defaultValue disabled="disabled">
                          Seleccione comuna
                        </MenuItem>
                        {communeList.map((item, index) => (
                          <MenuItem key={index} value={item.id_commune}>
                            {item.name_commune}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography id="demo-simple-select-label">
                        Seleccione Tipo de bien
                      </Typography>
                      <Select
                        value={typeOfAsset}
                        onChange={(event) => {
                          setTypeOfAsset(event.target.value);
                        }}
                        required
                        label="Tipo de bien"
                        sx={{ minWidth: 300, my: 2 }}
                      >
                        <MenuItem value="" defaultValue disabled="disabled">
                          Seleccione tipo de bien
                        </MenuItem>
                        {typeOfAssetList.map((item, index) => (
                          <MenuItem key={index} value={item.id_type_of_assets}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography>
                        Ingrese dirección y número del bien
                      </Typography>
                      <GoogleMaps/>
                      <Typography>Rol</Typography>
                      <TextField
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese Rol del bien"
                        value={rol}
                        required
                        onChange={(event) => {
                          setRol(event.target.value);
                        }}
                      />
                      <Typography>Área terreno</Typography>
                      <TextField
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese área terreno"
                        type="number"
                        value={terrainArea}
                        required
                        onChange={(event) => {
                          setTerrainArea(event.target.value);
                        }}
                      />
                      <Typography>Área en construcción</Typography>
                      <TextField
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese área en construcción del bien"
                        type="number"
                        value={constructionArea}
                        required
                        onChange={(event) => {
                          setConstructionArea(event.target.value);
                        }}
                      />
                      <Typography>Habitación</Typography>
                      <TextField
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese n° habitaciones del bien"
                        type="number"
                        value={bedroom}
                        required
                        onChange={(event) => {
                          setBedroom(event.target.value);
                        }}
                      />
                      <Typography>Baño</Typography>
                      <TextField
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese n° baños del bien"
                        type="number"
                        value={bathroom}
                        required
                        onChange={(event) => {
                          setBathroom(event.target.value);
                        }}
                      />
                    </Grid>
                    <Typography id="demo-simple-select-label">
                      Seleccione Supervisor
                    </Typography>
                    <Select
                      value={supervisor}
                      onChange={(event) => {
                        setSupervisor(event.target.value);
                      }}
                      required
                      sx={{ minWidth: 300, my: 2 }}
                    >
                      <MenuItem value="" selected disabled>
                        Seleccione supervisor
                      </MenuItem>
                      {supervisorList.map((item, index) => (
                        <MenuItem key={index} value={item.email}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Grid>
                      <Link to={"/"} color="success">
                        <Button
                          type="submit"
                          sx={{ m: 2 }}
                          variant="contained"
                          title="Volver"
                        >
                          <Typography variant="h6">
                            <IoIosArrowBack style={{ fontSize: "25px" }} />
                            <Typography sx={{ mx: 1 }} variant>
                              Volver
                            </Typography>
                          </Typography>
                        </Button>
                      </Link>
                      <Button
                        title="Ingresar"
                        variant="contained"
                        color="success"
                        onClick={() => createClient()}
                      >
                        <Typography variant="h6">Ingresar</Typography>
                      </Button>
                    </Grid>
                  </FormGroup>
                </Grid>
              </Container>
            </Box>
            <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
              <Container sx={{ minWidth: 500, my: 3 }}>
                <Typography
                  className="text-center align-items-center"
                  variant="h6"
                >
                  Ingresar Datos Cliente
                </Typography>
                <hr />
                <FormGroup>
                  <Grid
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    container
                  >
                    <Typography>Rut cliente</Typography>
                    <TextField
                      sx={{ minWidth: 350, my: 2 }}
                      placeholder="Ej: 7654564-0"
                      value={rutClient}
                      required
                      onChange={(event) => {
                        setRutClient(event.target.value);
                      }}
                    />
                    <Typography>Nombre cliente</Typography>
                    <TextField
                      sx={{ minWidth: 350, my: 2 }}
                      placeholder="Ej: Juan Pérez"
                      value={nameClient}
                      required
                      onChange={(event) => {
                        setNameClient(event.target.value);
                      }}
                    />
                    <Typography>Correo cliente</Typography>
                    <TextField
                      sx={{ minWidth: 350, my: 2 }}
                      placeholder="Ej: juanperez@gmail.com"
                      value={emailClient}
                      required
                      onChange={(event) => {
                        setEmailClient(event.target.value);
                      }}
                    />
                    <Typography>Teléfono cliente</Typography>
                    <Grid sx={{ my: 2 }}>
                      <TextField
                        sx={{ width: 90, mx: 1 }}
                        disabled
                        value="+ 56"
                      ></TextField>
                      <TextField
                        sx={{ minWidth: 245 }}
                        placeholder="Ej: 98787678"
                        value={phoneClient}
                        required
                        onChange={(event) => {
                          setPhoneClient(event.target.value);
                        }}
                      />
                    </Grid>
                    <Grid
                      direction="column"
                      alignItems="center"
                      justifyContent="center"
                      container
                    >
                      <Typography>Seleccione Región</Typography>
                      <Select
                        value={region}
                        onChange={(event) => {
                          setRegion(event.target.value);
                          handleCommune(event.target.value);
                        }}
                        required
                        sx={{ minWidth: 300, my: 2 }}
                      >
                        <MenuItem value="" defaultValue disabled="disabled">
                          <em>Seleccione región</em>
                        </MenuItem>
                        {regionList.map((item, index) => (
                          <MenuItem key={index} value={item.id_region}>
                            {item.name_region}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography id="demo-simple-select-label">
                        Seleccione Comuna
                      </Typography>
                      <Select
                        value={commune}
                        onChange={(event) => {
                          setCommune(event.target.value);
                        }}
                        required
                        label="Comuna"
                        sx={{ minWidth: 300, my: 2 }}
                      >
                        <MenuItem value="" defaultValue disabled="disabled">
                          Seleccione comuna
                        </MenuItem>
                        {communeList.map((item, index) => (
                          <MenuItem key={index} value={item.id_commune}>
                            {item.name_commune}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography id="demo-simple-select-label">
                        Seleccione Tipo de bien
                      </Typography>
                      <Select
                        value={typeOfAsset}
                        onChange={(event) => {
                          setTypeOfAsset(event.target.value);
                        }}
                        required
                        label="Tipo de bien"
                        sx={{ minWidth: 300, my: 2 }}
                      >
                        <MenuItem value="" defaultValue disabled="disabled">
                          Seleccione tipo de bien
                        </MenuItem>
                        {typeOfAssetList.map((item, index) => (
                          <MenuItem key={index} value={item.id_type_of_assets}>
                            {item.description}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography>
                        Ingrese dirección y número del bien
                      </Typography>
                      <GoogleMaps />
                      <Typography>Rol</Typography>
                      <TextField
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese Rol del bien"
                        value={rol}
                        required
                        onChange={(event) => {
                          setRol(event.target.value);
                        }}
                      />
                      <Typography>Área terreno</Typography>
                      <TextField
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese área terreno"
                        type="number"
                        value={terrainArea}
                        required
                        onChange={(event) => {
                          setTerrainArea(event.target.value);
                        }}
                      />
                      <Typography>Área en construcción</Typography>
                      <TextField
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese área en construcción del bien"
                        type="number"
                        value={constructionArea}
                        required
                        onChange={(event) => {
                          setConstructionArea(event.target.value);
                        }}
                      />
                      <Typography>Habitación</Typography>
                      <TextField
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese n° habitaciones del bien"
                        type="number"
                        value={bedroom}
                        required
                        onChange={(event) => {
                          setBedroom(event.target.value);
                        }}
                      />
                      <Typography>Baño</Typography>
                      <TextField
                        sx={{ minWidth: 300, my: 2 }}
                        placeholder="Ingrese n° baños del bien"
                        type="number"
                        value={bathroom}
                        required
                        onChange={(event) => {
                          setBathroom(event.target.value);
                        }}
                      />
                    </Grid>
                    <Typography id="demo-simple-select-label">
                      Seleccione Supervisor
                    </Typography>
                    <Select
                      value={supervisor}
                      onChange={(event) => {
                        setSupervisor(event.target.value);
                      }}
                      required
                      sx={{ minWidth: 300, my: 2 }}
                    >
                      <MenuItem value="" selected disabled>
                        Seleccione supervisor
                      </MenuItem>
                      {supervisorList.map((item, index) => (
                        <MenuItem key={index} value={item.email}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                    <Grid>
                      <Link to={"/"} color="success">
                        <Button
                          type="submit"
                          sx={{ m: 3 }}
                          variant="contained"
                          title="Volver"
                        >
                          <Typography variant="h6">
                            <IoIosArrowBack style={{ fontSize: "25px" }} />
                            <Typography sx={{ mx: 1 }} variant>
                              Volver
                            </Typography>
                          </Typography>
                        </Button>
                      </Link>
                      <Button
                        title="Ingresar"
                        variant="contained"
                        color="success"
                        onClick={() => createClient()}
                      >
                        <Typography variant="h6">Ingresar</Typography>
                      </Button>
                    </Grid>
                    </Grid>
                </FormGroup>
              </Container>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}
