import React, { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";
import {
  Grid,
  Button,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import { Container } from "@mui/system";
import { HiDocumentDownload } from "react-icons/hi";

export default function AppreciationAsset() {
  const [idClient] = useState(sessionStorage.getItem("idUser"));
  const [counter, setCounter] = useState(0);
  const [show, setShow] = useState(0);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const rows = [
    {
      label: "Nombre Cliente",
      field: "nameClient",
    },
    {
      label: "Tipo de bien",
      field: "typeOfAsset",
    },
    {
      label: "Dirección",
      field: "address",
    },
    {
      label: "Rol",
      field: "rol",
    },
    {
      label: "Valoración UF",
      field: "valueUf",
    },
    {
      label: "Valoración en pesos",
      field: "valueP",
    },
    {
      label: "Calidad valoración",
      field: "valueUfReport",
    },
    {
      label: "Acción",
      field: "actionBtn",
    },
  ];
  const [datatable, setDatatable] = useState({
    columns: rows,
    rows: [],
  });
  useEffect(() => {
    fetchClient();
    const interval = setInterval(() => {
      setCounter((state) => {
        if (state > 0) {
          return state - 1;
        }
      });
      return () => clearInterval(interval);
    }, 1000);
  }, []);
  const downloadDoc = async (id) => {
    await axios.get(APIRoute.doc + id).then(({ data }) => {
      for (let i = 0; i <= data.length; i++) {
        if (data[i].id_archive_type === 1) {
          openInNewTab(APIRoute.url + data[i].name_file);
        }
      }
    });
  };
  const fetchClient = async () => {
    await axios.get(APIRoute.appreciations).then(({ data }) => {
      let asset = [];
      let valorPesos = [];
      for (let j = 0; j < data.length; j++) {
        valorPesos[j] = data[j]["value_uf_report"] * data[j]["value_uf_saved"];
        if (data[j].id_type_of_assets === 1) {
          asset[j] = "CASA";
        } else if (data[j].id_type_of_assets === 2) {
          asset[j] = "DEPARTAMENTO";
        }
      }
      setShow(1);
      setDatatable({
        columns: rows,
        rows: data.map((appreciation, index) => {
          return {
            id: appreciation.id_appreciation,
            nameClient: appreciation.name_client,
            typeOfAsset: asset[index],
            address: appreciation.address,
            rol: appreciation.rol,
            valueUf: appreciation.value_uf_report.toFixed(0),
            valueP: valorPesos[index].toFixed(0),
            valueUfReport: appreciation.quality,
            actionBtn: (
              <>
                <Button
                  sx={{ p: 1 }}
                  spacing={2}
                  title="Descargar"
                  variant="contained"
                  color="warning"
                  onClick={() => downloadDoc(appreciation.id_appreciation)}
                >
                  <HiDocumentDownload
                    style={{ width: "2rem", height: "2em" }}
                  />
                </Button>
              </>
            ),
          };
        }),
      });
    });
  };
  return (
    <Container sx={{ my: 5 }}>
      <Typography>
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Typography variant="h5">Listado de valoraciones</Typography>
          {show ? (
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
              <MDBDataTableV5
                hover
                entriesOptions={[5, 20, 25]}
                entries={5}
                pagesAmount={4}
                searchTop
                searchBottom={false}
                searchLabel="Busqueda general"
                exportToCSV
                responsive
                data={datatable}
              />
            </Paper>
          ) : (
            <CircularProgress />
          )}
        </Grid>
      </Typography>
    </Container>
  );
}
