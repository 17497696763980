import React, { useEffect, useState } from "react";
import { MDBDataTableV5 } from "mdbreact";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";
import { Link } from "react-router-dom";
import {
  Grid,
  Box,
  Button,
  Typography,
  Paper,
  CircularProgress,
} from "@mui/material";
import { FcPlus } from "react-icons/fc";
import { Container } from "@mui/system";
import { RiEdit2Fill} from "react-icons/ri";

export default function AdminList() {
  const [adminList, setAdminList] = useState([]);
  const [show, setShow] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };
  const rows = [
    {
      label: "Rut cliente",
      field: "rutClient",
    },
    {
      label: "Nombre cliente",
      field: "nameClient",
    },
    {
      label: "Teléfono Cliente",
      field: "phoneClient",
    },
    {
      label: "Correo Cliente",
      field: "emailClient",
    },
    {
      label: "Acción",
      field: "actionBtn",
    },
  ];
  const [datatable, setDatatable] = useState({
    columns: rows,
    rows: [],
  });
  useEffect(() => {
    fetchAdmin();
  }, []);
  const downloadDoc = async (id) => {
    await axios.get(APIRoute.doc + id).then(({ data }) => {
      for (let i = 0; i <= data.length; i++) {
        if (data[i].id_archive_type === 1) {
          openInNewTab(APIRoute.url + data[i].name_file);
        }
      }
    });
  };
  const fetchAdmin = async () => {
    let tokenStr = sessionStorage.getItem("token");
    await axios
      .get(APIRoute.clients, {
        headers: {
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods":
            "GET, POST, PATCH, PUT, DELETE, OPTIONS",
          "Access-Control-Allow-Headers": "Origin, Content-Type, X-Auth-Token",
          Authorization: `Bearer ${tokenStr}`,
        },
      })
      .then(({ data }) => {
        setShow(1);
        setAdminList(data);
        setDatatable({
          columns: rows,
          rows: data.map((client) => {
            return {
              id: client.id_client,
              rutClient: client.rut_client,
              nameClient: client.name_client,
              phoneClient: client.phone_client,
              emailClient: client.email_client,
              actionBtn: (
                <>
                  <Link to={APIRoute.editClient + client.id_client}>
                    <Button
                      spacing={2}
                      title="Editar"
                      variant="contained"
                      color="info"
                      sx={{ p: 1 }}
                    >
                      <RiEdit2Fill style={{ width: "2rem", height: "1.5em" }} />
                    </Button>
                  </Link>
                </>
              ),
            };
          }),
        });
      });
  };
  return (
    <Box component="main" sx={{ p: 3 }}>
      <Grid>
        <Grid
          sx={{ p: 3}}
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
        <Typography variant="h5">Listado de clientes</Typography>
          <Link className="btn btn-primary active" to={APIRoute.create}>
            <FcPlus style={{ fontSize: "28px"}}  />
            <Typography sx={{mx:1}} variant> Crear nuevo cliente</Typography>
          </Link>
        </Grid>
      </Grid>
      <Container>
        <Typography>
          <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            {show ? (
              <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <MDBDataTableV5
                  hover
                  entriesOptions={[5, 20, 25]}
                  entries={5}
                  pagesAmount={4}
                  searchTop
                  searchBottom={false}
                  searchLabel="Busqueda general"
                  exportToCSV
                  responsive
                  data={datatable}
                />
              </Paper>
            ) : (
              <CircularProgress />
            )}
          </Grid>
        </Typography>
      </Container>
    </Box>
  );
}
