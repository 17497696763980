import React, { useState } from "react";
import axios from "axios";
import APIRoute from "../routersAPI/routes.json";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "mdbreact/dist/css/mdb.css";
import logo from "../../logo.svg";
import CircularProgress from "@mui/material/CircularProgress";
import {
  FormGroup,
  TextField,
  Alert,
  Button,
  Grid,
  Paper,
  Typography
} from "@mui/material";
import ClientMenu from "../menu/client.component";
import AdministratorMenu from "../menu/administrator.component";

export default function Access() {
  const [uName, setUName] = useState("");
  const [uPass, setUPass] = useState("");
  let [validationError, setValidationError] = useState({});
  const [show, setShow] = useState(0);

  const handleSubmit = async (e) => {
    const formData = new FormData();
    formData.append("uName", uName);
    formData.append("uPass", uPass);

    await axios.post(APIRoute.access, formData).then(({ data }) => {
      if (data.status === 200) {
        setShow(0);
        sessionStorage.setItem("session", 1);
        sessionStorage.setItem("typeLogin", data.typeLogin);
        sessionStorage.setItem("idUser", data.idUser);
        sessionStorage.setItem("sessionHidden", 1);
        sessionStorage.setItem("token", data.token);
        window.location.reload();
      } else if (data.status === 401) {
        setShow(0);
        setValidationError("Código ya utilizado");
      } else if (data.status === 400) {
        setShow(0);
        setValidationError("Correo o código inválidos");
      } else if (data.status === 500) {
        setShow(0);
        setValidationError("No existe conexión al servicio");
      }
    });
  };
  return (
    <>
      {sessionStorage.getItem("session") === 0 ||
      sessionStorage.getItem("session") === null ? (
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <div className="imgfondo">
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ flexGrow: 2, display: { xs: "flex", md: "none" } }}
              style={{ marginLeft: "1rem", height: "70%", minHeight:'90vh', width:"90vw"}}
            >
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ background: "#F0F0F0"}}
              >
              <Grid sx={{my: 2}}>
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      bottom: "10px",
                      right: "10px",
                      width: "100px",
                      marginLeft: 10
                    }}
                  />
                  </Grid>
                  <Typography variant="h6">Bienvenido al Sistema AVM</Typography>
                <div className="text-center align-items-center">
                  <hr />
                  <Typography variant="h6" sx={{my:3}}>Ingrese sus datos por favor</Typography>
                  <div>
                    {Object.keys(validationError).length > 0 && (
                      <Grid>
                        <Grid>
                          <Alert severity="error">
                            <li>{validationError}</li>
                          </Alert>
                        </Grid>
                      </Grid>
                    )}
                    <form onSubmit={handleSubmit}>
                      <FormGroup>
                        <TextField
                        sx={{my:2}}
                          required
                          label="Correo Electronico"
                          value={uName}
                          onChange={(event) => {
                            setUName(event.target.value);
                          }}
                          style={{ marginLeft: "1rem", width: "80vw"}}
                        />
                      </FormGroup>
                      <FormGroup>
                        <TextField
                        sx={{my:2}}
                          required
                          type="password"
                          autoComplete="on"
                          label="Código de acceso"
                          value={uPass}
                          onChange={(event) => {
                            setUPass(event.target.value);
                          }}
                          style={{ marginLeft: "1rem", width: "80vw"}}
                        />
                      </FormGroup>
                      <>
                        {show ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            onClick={() => [
                              setShow(Boolean(1)),
                              handleSubmit(),
                            ]}
                            disabled={show}
                            variant="contained"
                          >
                            <>Ingresar</>
                          </Button>
                        )}
                      </>
                      <hr />
                      <div>
                        <p>
                          © 2023 Valuaciones de Chile. Todos los Derechos
                          Reservados
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
              sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}
              style={{
                background: "#F0F0F0",
                marginLeft: "35vw",
              }}
              width="500px"
            >
              <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ background: "#F0F0F0"}}
              >
                <Grid sx={{my: 2}}>
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      bottom: "10px",
                      right: "10px",
                      width: "100px",
                      margin: 10,
                    }}
                  />
                   </Grid>
                  <Typography variant="h6">Bienvenido al Sistema AVM</Typography>
                <div className="text-center align-items-center">
                  <hr />
                  <Typography variant="h6">Ingrese sus datos por favor</Typography>
                  <br />
                  <br />
                  <div>
                    {Object.keys(validationError).length > 0 && (
                      <Grid>
                        <Grid>
                          <Alert severity="error">
                            <li>{validationError}</li>
                          </Alert>
                        </Grid>
                      </Grid>
                    )}
                    <br />
                    <form onSubmit={handleSubmit}>
                      <FormGroup>
                        <TextField
                          required
                          label="Correo Electronico"
                          value={uName}
                          onChange={(event) => {
                            setUName(event.target.value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <br />
                        <TextField
                          required
                          type="password"
                          autoComplete="on"
                          label="Código de acceso"
                          value={uPass}
                          onChange={(event) => {
                            setUPass(event.target.value);
                          }}
                        />
                      </FormGroup>
                      <br />
                      <>
                        {show ? (
                          <CircularProgress />
                        ) : (
                          <Button
                            onClick={() => [
                              setShow(Boolean(1)),
                              handleSubmit(),
                            ]}
                            disabled={show}
                            variant="contained"
                          >
                            <>Ingresar</>
                          </Button>
                        )}
                      </>
                      <hr />
                      <div>
                        <p>
                          © 2023 Valuaciones de Chile. Todos los Derechos
                          Reservados
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </Grid>
            </Grid>
          </div>
        </Paper>
      ) : (
        <>
          {sessionStorage.getItem("typeLogin") === "client" ? (
            <ClientMenu />
          ) : sessionStorage.getItem("typeLogin") === "administrator" ? (
            <AdministratorMenu />
          ) : (
            <>asasa</>
          )}
        </>
      )}
    </>
  );
}
