import React, { useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { FiLogOut } from "react-icons/fi";
import IconButton from "@mui/material/IconButton";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { Grid, Button, MenuItem, Menu } from "@mui/material";
import AdminList from "../admin/admiList.component";
import logo from "../../logo.svg";
import CreateClient from "../admin/createClient.component";
import EditClient from "../admin/editClient.component";
import AppreciationAsset from "../admin/appreciationAsset.component";
import { Container } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import "../../App.css";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export default function AdministratorMenu() {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const darkTheme = createTheme({
    palette: {
      primary: {
        main: "#0A0F3E",
      },
    },
  });
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  return (
    <Router>
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static">
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <img
                src={logo}
                alt="Logo"
                className="logo"
                style={{ marginLeft: "3.5rem", width: "3.5rem", height: "100%" }}
                sx={{ display: { xs: "4rem", md: "flex" }, mr: 1 }}
              />
              <Button component={Link} to={"/"}>
                <Typography color="white"  component="div" sx={{ flexGrow: 1, fontWeight: 700}}>
                  Valuaciones SPA
                </Typography>
              </Button>

              <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
                <Button
                  component={Link}
                  to={"/"}
                  onClick={handleCloseNavMenu}
                  sx={{
                    my: 1,
                    color: "white",
                    display: "block",
                    flexGrow: 4,
                    position: "fixed",
                    right: "5vw",
                    top: "1vh",
                  }}
                >
                  {sessionStorage.getItem("session") !== 0 ||
                  sessionStorage.getItem("session") != null ? (
                    <div
                      className="logout text-white"
                      color="inherit"
                      onClick={(e) => {
                        setTimeout(function () {
                          window.location.href = "/";
                        }, 3000);
                        sessionStorage.removeItem("session");
                        sessionStorage.removeItem("typeLogin");
                        sessionStorage.removeItem("token");
                        sessionStorage.removeItem("id");
                        sessionStorage.removeItem("addressApi");
                        sessionStorage.removeItem("placeid");
                        sessionStorage.setItem("sessionHidden", 0);
                      }}
                    >
                      <FiLogOut
                        style={{
                          marginLeft: "1rem",
                          width: "4rem",
                          height: "20",
                        }}
                        className="float-right text-white"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </Button>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleOpenNavMenu}
                  color="inherit"
                  style={{ position: "fixed", left: "1vw", top: "1vh" }}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorElNav}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                  open={Boolean(anchorElNav)}
                  onClose={handleCloseNavMenu}
                  sx={{
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <MenuItem
                    component={Link}
                    to={"/"}
                    onClick={handleCloseNavMenu}
                  >
                    <Typography textAlign="center">
                      Listado de Clientes
                    </Typography>
                  </MenuItem>
                  <MenuItem
                    component={Link}
                    to={"/appreciation"}
                    onClick={handleCloseNavMenu}
                  >
                    <Typography textAlign="center">
                      Listado de Valoraciones
                    </Typography>
                  </MenuItem>
                </Menu>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
                <Button
                  component={Link}
                  to={"/"}
                  onClick={handleCloseNavMenu}
                  sx={{ mx: 3 }}
                >
                  <Typography color="white">Listado de clientes</Typography>
                </Button>
                <Button
                  component={Link}
                  to={"/appreciation"}
                  onClick={handleCloseNavMenu}
                >
                  <Typography color="white">Listado de valoraciones</Typography>
                </Button>
                <Button
                  component={Link}
                  to={"/"}
                  onClick={handleCloseNavMenu}
                  sx={{ my: 2, color: "white", display: "block", flexGrow: 4 }}
                >
                  {sessionStorage.getItem("session") !== 0 ||
                  sessionStorage.getItem("session") != null ? (
                    <div
                      className="logout text-white"
                      color="inherit"
                      onClick={(e) => {
                        sessionStorage.removeItem("session");
                        sessionStorage.removeItem("typeLogin");
                        sessionStorage.removeItem("token");
                        sessionStorage.removeItem("id");
                        sessionStorage.removeItem("addressApi");
                        sessionStorage.removeItem("placeid");
                        sessionStorage.setItem("sessionHidden", 0);
                        window.location.href = "/";
                      }}
                    >
                      <FiLogOut
                        style={{
                          marginLeft: "1rem",
                          width: "5rem",
                          height: "25",
                        }}
                        className="float-right text-white"
                      />
                    </div>
                  ) : (
                    <></>
                  )}
                </Button>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </ThemeProvider>
      <Grid>
        <Routes>
          <Route path="/" element={<AdminList />} />
          <Route path="/experiences" element={<AdminList />} />
          <Route path="/appreciation" element={<AppreciationAsset />} />
          <Route path="/client/" element={<CreateClient />} />
          <Route path="api/client/edit/:id" element={<EditClient />} />
        </Routes>
      </Grid>
    </Router>
  );
}
